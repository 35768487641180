import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button text-button primary_button", { 'icon-button': _ctx.icon, disabled: !_ctx.enabled || _ctx.isLoading }]),
    disabled: !_ctx.enabled || _ctx.isLoading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled && !_ctx.isLoading ? _ctx.click() : ''))
  }, [
    (_ctx.icon && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          draggable: "false",
          class: _normalizeClass(["icon", { left: _ctx.isShowText }]),
          src: _ctx.enabled ? _ctx.icon : _ctx.disabledIcon ?? _ctx.icon
        }, null, 10, _hoisted_2))
      : (_ctx.icon && _ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["icon loading-indicator", { left: _ctx.isShowText }])
          }, null, 2))
        : _createCommentVNode("", true),
    (_ctx.isShowText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: _normalizeClass({ hasIcon: _ctx.icon || _ctx.rightIcon })
        }, _toDisplayString(_ctx.text), 3))
      : _createCommentVNode("", true),
    (_ctx.rightIcon && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          class: "icon right",
          src: _ctx.enabled ? _ctx.rightIcon : _ctx.disabledIcon ?? _ctx.rightIcon
        }, null, 8, _hoisted_3))
      : (_ctx.rightIcon && _ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["icon loading-indicator", { left: _ctx.isShowText }])
          }, null, 2))
        : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}