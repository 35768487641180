
import Vue3DraggableResizable from "vue3-draggable-resizable";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import useInputVars from "@/lib/compositional-logic/useInputVars";

export default defineComponent({
  name: "TextInputDraggableResizable",
  components: {
    Dropdown,
    ToggleCheckbox,
    "vue-draggable-resizable": Vue3DraggableResizable,
  },
  props: {
    type: { type: Number, default: 1 },
    isOptionalText: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    isStamp: { type: Boolean, default: false },
    id: { type: String, default: "" },
    index: { type: Number, default: 0 },
    initX: { type: Number, default: 0 },
    initY: { type: Number, default: 0 },
    initW: { type: Number, default: 200 },
    initH: { type: Number, default: 50 },
    initPadding: { type: Number, default: 0 },
    minHeight: { type: Number, default: 0 },
    signers: { type: Array, default: [] as never[] },
    initFontSize: { type: String, default: "10" },
    defaultSigner: { type: String },
    text: { type: String, default: "" },
    stampType: { type: Boolean, default: true },
    isEdit: { type: Boolean, default: false },
    suggestions: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
  emits: [
    "onRemoveItem",
    "onUpdate",
    "onUpdateSigner",
    "isCheckedByDefault",
    "isRequired",
    "updateCheckBoxText",
    "updateFontSize",
  ],
  setup(props, { emit }) {
    const state = reactive({
      isActive: !props.isEdit || props.defaultSigner == "" ? true : false,
      checkBoxText: props.text,
      fontSize: props.initFontSize,
      suggestSelectIndex: -1,
    });

    const { fontSizes, getFontSizeMobile } = useInputVars();

    const fontSizeMob = computed(() => getFontSizeMobile(state.fontSize));

    const isMobile = inject("isMobile") as any;
    const minWidth = computed(() => {
      const minW = props.type === 1 ? 200 : 250;

      return isMobile.value ? minW - 40 : minW;
    });

    const fieldInfo = ref({
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    });

    const dragging = (position: any) => {
      fieldInfo.value.x = position.x > 0 ? position.x : 0;
      fieldInfo.value.y = position.y > 0 ? position.y : 0;
    };

    const filteredSuggestions = computed(() => {
      return props.suggestions.filter(
        (i) =>
          i &&
          state.checkBoxText != i &&
          (state.checkBoxText == "" || i.includes(state.checkBoxText))
      );
    });
    const suggestionHeight = computed(() => {
      return Math.min(filteredSuggestions.value.length, 3);
    });
    watch(
      () => fieldInfo.value,
      (val, old) => {
        emit("onUpdate", val);
      },
      { deep: true }
    );

    watch(
      () => props.initX,
      (val, old) => {
        fieldInfo.value.x = val;
      }
    );

    watch(
      () => props.initY,
      (val, old) => {
        fieldInfo.value.y = val;
      }
    );

    watch(
      () => props.initH,
      (val, old) => {
        fieldInfo.value.h = val;
      }
    );

    watch(
      () => props.initW,
      (val, old) => {
        fieldInfo.value.w = val;
      }
    );

    watch(
      () => props.initFontSize,
      (val, old) => {
        state.fontSize = val;
      }
    );

    watch(
      () => state.checkBoxText,
      (val) => {
        emit("updateCheckBoxText", val);
      }
    );

    watch(
      () => state.fontSize,
      (val) => {
        emit("updateFontSize", val);
      }
    );

    onMounted(() => {
      fieldInfo.value.x = props.initX;
      fieldInfo.value.y = props.initY;
      fieldInfo.value.w = props.initW;
      fieldInfo.value.h = props.initH;
    });

    return {
      ...toRefs(state),
      fieldInfo,
      dragging,
      isMobile,
      minWidth,
      fontSizes,
      fontSizeMob,
      filteredSuggestions,
      suggestionHeight,
    };
  },
  methods: {
    removeItem() {
      this.$emit("onRemoveItem", this.index);
    },
    resizing(payload: any) {
      this.fieldInfo.x = payload.x;
      this.fieldInfo.y = payload.y;
      this.fieldInfo.w = payload.w;
      this.fieldInfo.h = payload.h;
    },
    onUpdateSigner(payload: any) {
      const signerOrder = this.signers.find(
        (signer: any) =>
          signer.id === payload ||
          signer.placeholder === payload ||
          signer.email === payload
      );

      this.$emit("onUpdateSigner", signerOrder);
    },
    selectSuggestion(suggest: string) {
      this.checkBoxText = suggest;
    },
    keydown(event: KeyboardEvent) {
      switch (event.code.toLowerCase()) {
        case "arrowup":
          if (this.suggestSelectIndex == 0) return;
          this.suggestSelectIndex -= 1;
          this.scrollToItem(this.suggestSelectIndex);
          break;
        case "arrowdown":
          if (this.suggestSelectIndex >= this.filteredSuggestions.length - 1)
            return;
          this.suggestSelectIndex += 1;
          this.scrollToItem(this.suggestSelectIndex);
          break;
        case "enter":
          if (
            this.filteredSuggestions.length > 0 &&
            this.suggestSelectIndex > -1
          ) {
            event.preventDefault();
            this.checkBoxText =
              this.filteredSuggestions[this.suggestSelectIndex];
            this.suggestSelectIndex = -1;
          }
          break;
        default:
          break;
      }
    },

    input() {
      this.suggestSelectIndex = -1;
    },

    scrollToItem(index: number) {
      const scrollIndex = index - 2 < 0 ? 0 : index - 2;
      (this.$refs.suggest as HTMLElement).scrollTop = scrollIndex * 40;
    },
  },
});
