import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c5001c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  type: "checkbox",
  class: "empty-checkbox"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "draggable-input-area-circle"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "toggle-checkbox-container"
}
const _hoisted_12 = {
  key: 0,
  class: "toggle-checkbox-field"
}
const _hoisted_13 = { class: "toggle-checkbox-text" }
const _hoisted_14 = {
  key: 1,
  class: "thin-divider"
}
const _hoisted_15 = {
  key: 2,
  class: "toggle-checkbox-field"
}
const _hoisted_16 = { class: "toggle-checkbox-text" }
const _hoisted_17 = {
  key: 3,
  class: "thin-divider"
}
const _hoisted_18 = { class: "selection-field" }
const _hoisted_19 = { class: "toggle-checkbox-text no-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_vue_draggable_resizable = _resolveComponent("vue-draggable-resizable")!

  return (_openBlock(), _createBlock(_component_vue_draggable_resizable, {
    class: _normalizeClass([{
      'draggable-resizable': !_ctx.isStamp,
      'draggable-resizable-stamp': _ctx.isStamp,
    }, "input-draggable"]),
    "class-name-handle": "my-handle-class",
    "class-name-active": "my-active-class upper",
    key: _ctx.id + 99,
    parent: true,
    x: _ctx.fieldInfo.x,
    y: _ctx.fieldInfo.y,
    "min-w": !_ctx.isStamp ? _ctx.minWidth : _ctx.fieldInfo.w + 20,
    "min-h": !_ctx.isStamp ? _ctx.minHeight : 0,
    w: _ctx.isStamp ? _ctx.fieldInfo.w + _ctx.initPadding * 2 : _ctx.fieldInfo.w,
    h: _ctx.isStamp ? _ctx.fieldInfo.h + _ctx.initPadding * 2 : _ctx.fieldInfo.h,
    resizable: !_ctx.isStamp,
    active: _ctx.isActive,
    "lock-aspect-ratio": _ctx.isStamp,
    onActivated: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isActive = true)),
    onDeactivated: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isActive = false)),
    onResizing: _cache[12] || (_cache[12] = (payload) => _ctx.resizing(payload)),
    onDragging: _cache[13] || (_cache[13] = (payload) => _ctx.dragging(payload))
  }, {
    default: _withCtx(() => [
      (!_ctx.isStamp)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["draggable-input-area input", { 'align-top': _ctx.type === 3 }])
          }, [
            (_ctx.type === 3)
              ? (_openBlock(), _createElementBlock("input", _hoisted_1))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("textarea", {
              class: "input-draggable-resizable",
              placeholder: _ctx.$t('templateVars.placeHolder'),
              style: _normalizeStyle('--fz:' + _ctx.fontSize + 'px;--fz-mob:' + _ctx.fontSizeMob + 'px'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkBoxText) = $event)),
              onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args))),
              onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
            }, null, 44, _hoisted_2), [
              [_vModelText, _ctx.checkBoxText]
            ]),
            (_ctx.isActive)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "remove-icon",
                  src: require('icons/delete.svg'),
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)))
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.isActive)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  class: "remove-icon-mobile",
                  src: require('icons/delete-cross.svg'),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)))
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "container-circle",
              style: _normalizeStyle({
          width: _ctx.fieldInfo.h + 'px',
          height: _ctx.fieldInfo.h + 'px',
        })
            }, [
              (_ctx.stampType)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "circle-draggable-resizable",
                    alt: "stamp image",
                    src: require('images/stamp-orange.svg')
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "circle-draggable-resizable square",
                    alt: "stamp image",
                    src: require('images/square-stamp-orange.svg')
                  }, null, 8, _hoisted_7))
            ], 4),
            (_ctx.isActive)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass(["remove-icon", { absolute: _ctx.isStamp }]),
                  src: require('icons/delete.svg'),
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)))
                }, null, 10, _hoisted_8))
              : _createCommentVNode("", true),
            (_ctx.isActive)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "remove-icon-mobile",
                  src: require('icons/delete-cross.svg'),
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)))
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])),
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            class: "options-wrapper",
            key: _ctx.id + 98
          }, [
            (_ctx.type === 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  ref: "suggest",
                  class: _normalizeClass(["suggestion-select", { hidden: _ctx.filteredSuggestions.length <= 0 }]),
                  style: _normalizeStyle({
          'max-height': _ctx.suggestionHeight * 40 + 'px',
          height: _ctx.suggestionHeight * 40 + 'px',
        })
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSuggestions, (suggest, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["suggest-item", { selected: _ctx.suggestSelectIndex == index }]),
                      key: suggest,
                      onClick: ($event: any) => (_ctx.selectSuggestion(suggest))
                    }, _toDisplayString(suggest), 11, _hoisted_10))
                  }), 128))
                ], 6))
              : _createCommentVNode("", true),
            (!_ctx.isStamp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_ToggleCheckbox, {
                          class: "toggle-checkbox",
                          checked: _ctx.isChecked,
                          onOnChange: _cache[7] || (_cache[7] = (payload) => _ctx.$emit('isCheckedByDefault', payload))
                        }, null, 8, ["checked"]),
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("templateVars.toggleButtonText1")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createVNode(_component_ToggleCheckbox, {
                          class: "toggle-checkbox",
                          checked: _ctx.isOptionalText,
                          onOnChange: _cache[8] || (_cache[8] = (payload) => _ctx.$emit('isRequired', payload))
                        }, null, 8, ["checked"]),
                        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("templateVars.toggleButtonText2")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("templateVars.fontSize")), 1),
                    _createVNode(_component_dropdown, {
                      class: "dropdown-fontsize dropdown",
                      options: _ctx.fontSizes,
                      "default-option": _ctx.fontSize,
                      value: _ctx.fontSize,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.fontSize) = $event))
                    }, null, 8, ["options", "default-option", "value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.signers.length != 0)
              ? (_openBlock(), _createBlock(_component_dropdown, {
                  key: 2,
                  class: _normalizeClass(["dropdown", {
          'dropdown-checkbox': _ctx.type === 3,
          'dropdown-stamp': _ctx.fieldInfo.w < 140,
        }]),
                  options: _ctx.signers,
                  "default-option": _ctx.defaultSigner,
                  "place-holder": _ctx.$t('common.selectPlaceHolder'),
                  onOnChange: _ctx.onUpdateSigner
                }, null, 8, ["class", "options", "default-option", "place-holder", "onOnChange"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "x", "y", "min-w", "min-h", "w", "h", "resizable", "active", "lock-aspect-ratio"]))
}