import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Teleport as _Teleport, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df1c66fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "file-tab-mobile"
}
const _hoisted_2 = { class: "dropdown-row" }
const _hoisted_3 = { class: "pl-2 pr-2" }
const _hoisted_4 = { class: "container-draggable-resizable" }
const _hoisted_5 = { class: "dropdown-row" }
const _hoisted_6 = { class: "right-header" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "file-header pl-3" }
const _hoisted_11 = { class: "left-header" }
const _hoisted_12 = ["onDragstart"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["onDragstart"]
const _hoisted_15 = ["src"]
const _hoisted_16 = ["onDragstart"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "right-header" }
const _hoisted_19 = ["value", "onInput"]
const _hoisted_20 = { class: "file-content" }
const _hoisted_21 = { class: "container-draggable-resizable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!
  const _component_TextInputDraggableResizable = _resolveComponent("TextInputDraggableResizable")!
  const _component_DraggableContainer = _resolveComponent("DraggableContainer")!
  const _component_PdfPreviewVars = _resolveComponent("PdfPreviewVars")!
  const _component_FileTab = _resolveComponent("FileTab")!
  const _component_FileTabs = _resolveComponent("FileTabs")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_dropdown, {
            class: "file-dropdown",
            options: _ctx.localFiles,
            "default-option": _ctx.selectedFile.id,
            "onUpdate:value": _ctx.onSelectedFile
          }, null, 8, ["options", "default-option", "onUpdate:value"]),
          _createVNode(_component_MenuOptionPopup, null, {
            target: _withCtx(({ showMenuOption }) => [
              _createVNode(_component_TextButton, {
                icon: require('icons/more.svg'),
                "is-show-text": false,
                onOnClick: showMenuOption
              }, null, 8, ["icon", "onOnClick"])
            ]),
            options: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_TextButton, {
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (
                _ctx.addStamp(_ctx.selectedFile, _ctx.selectedFile.currentPage, {
                  initX: 0,
                  initY: 0,
                  isMobile: true,
                  isRound: _ctx.companyInfo?.imprintType === 1,
                  stampSize: _ctx.companyInfo?.imprintSize || 21,
                })
              )),
                  text: _ctx.$t('contractVars.stamp'),
                  icon: 
                _ctx.companyInfo?.imprintType === 1
                  ? require('icons/add-stamp.svg')
                  : require('icons/add-stamp-square-orange.svg')
              ,
                  "disabled-icon": 
                _ctx.companyInfo?.imprintType === 1
                  ? require('icons/add-stamp-grey.svg')
                  : require('icons/add-stamp-square-grey.svg')
              ,
                  class: "button-in-row"
                }, null, 8, ["text", "icon", "disabled-icon"]),
                _createVNode(_component_TextButton, {
                  onOnClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.addText(_ctx.selectedFile, false, _ctx.selectedFile.currentPage, {
                  initX: 0,
                  initY: 0,
                  isMobile: true,
                })
              )),
                  text: _ctx.$t('contractVars.textField'),
                  icon: require('icons/add-text.svg'),
                  class: "button-in-row"
                }, null, 8, ["text", "icon"]),
                _createVNode(_component_TextButton, {
                  onOnClick: _cache[2] || (_cache[2] = ($event: any) => (
                _ctx.addText(_ctx.selectedFile, true, _ctx.selectedFile.currentPage, {
                  initX: 0,
                  initY: 0,
                  isMobile: true,
                })
              )),
                  text: _ctx.$t('contractVars.checkBox'),
                  icon: require('icons/add-checkbox.svg'),
                  class: "button-in-row"
                }, null, 8, ["text", "icon"])
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_PdfPreviewVars, {
            "file-path": _ctx.selectedFile.path,
            "file-name": _ctx.selectedFile.name,
            "file-type": _ctx.selectedFile.type,
            page: _ctx.selectedFile.currentPage,
            onOnReady: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onReady(_ctx.selectedFile, $event))),
            onPageScaled: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onPageScaled(_ctx.selectedFile, $event))),
            onPageSized: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onPageSized(_ctx.selectedFile, $event))),
            onPageLoaded: _cache[6] || (_cache[6] = 
          (page) => {
            _ctx.isLoadingPdfPage = false;
            _ctx.onPageLoaded(_ctx.selectedFile, page);
          }
        ),
            class: "workspace"
          }, {
            default: _withCtx(() => [
              (!_ctx.isLoadingPdfPage)
                ? (_openBlock(), _createBlock(_component_DraggableContainer, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFile.inputs.filter(
              (input) => input.page === _ctx.selectedFile.currentPage
            ), (field, index) => {
                        return (_openBlock(), _createBlock(_component_TextInputDraggableResizable, {
                          key: field.id,
                          id: field.id,
                          index: index,
                          "is-optional-text": field.isOptionalText,
                          "is-stamp": field.isStamp,
                          "init-h": field.h,
                          "init-w": field.w,
                          "init-x": field.x,
                          "init-y": field.y,
                          "init-padding": field.padding,
                          "min-height": field.minHeight,
                          "init-font-size": field.fontSize,
                          "default-signer": 
              _ctx.localSigners.find(
                (signer) => signer.order === field.contractSignerOrder
              )?.id || ''
            ,
                          signers: _ctx.localSigners,
                          "is-checked": field.isChecked,
                          type: field.type,
                          text: field.text,
                          "stamp-type": field.isRoundStamp,
                          suggestions: _ctx.suggestions,
                          onIsCheckedByDefault: 
              (payload) =>
                _ctx.updateCheckedByDefault(_ctx.selectedFile, field.id, payload)
            ,
                          onIsRequired: 
              (payload) => _ctx.updateIsRequired(_ctx.selectedFile, field.id, payload)
            ,
                          onUpdateCheckBoxText: 
              (payload) => _ctx.updateCheckBoxText(_ctx.selectedFile, field.id, payload)
            ,
                          onUpdateFontSize: 
              (payload) => _ctx.updateFontSize(_ctx.selectedFile, field.id, payload)
            ,
                          onOnRemoveItem: ($event: any) => (_ctx.onRemoveInput(_ctx.selectedFile, field.id)),
                          onOnUpdate: 
              (payload) => _ctx.updateField(_ctx.selectedFile, field.id, payload)
            ,
                          onOnUpdateSigner: 
              (payload) => _ctx.updateSigner(_ctx.selectedFile, field.id, payload)
            
                        }, null, 8, ["id", "index", "is-optional-text", "is-stamp", "init-h", "init-w", "init-x", "init-y", "init-padding", "min-height", "init-font-size", "default-signer", "signers", "is-checked", "type", "text", "stamp-type", "suggestions", "onIsCheckedByDefault", "onIsRequired", "onUpdateCheckBoxText", "onUpdateFontSize", "onOnRemoveItem", "onOnUpdate", "onOnUpdateSigner"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["file-path", "file-name", "file-type", "page"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("input", {
                type: "text",
                class: "jump-page",
                value: _ctx.selectedFile.currentPage,
                onInput: _cache[7] || (_cache[7] = ($event) => _ctx.setPage(_ctx.selectedFile, $event))
              }, null, 40, _hoisted_8),
              _createElementVNode("p", null, "/" + _toDisplayString(_ctx.selectedFile.pageNums), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_TextButton, {
                class: "prev-page",
                icon: require('icons/bold-prev-arrow.svg'),
                "disabled-icon": require('icons/prev-arrow-grey.svg'),
                enabled: _ctx.selectedFile.currentPage >= 2,
                onOnClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.prevPage(_ctx.selectedFile)))
              }, null, 8, ["icon", "disabled-icon", "enabled"]),
              _createVNode(_component_TextButton, {
                class: "next-page",
                "right-icon": require('icons/next-arrow.svg'),
                "disabled-icon": require('icons/next-arrow-grey.svg'),
                enabled: _ctx.selectedFile.currentPage != _ctx.selectedFile.pageNums,
                onOnClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.nextPage(_ctx.selectedFile)))
              }, null, 8, ["right-icon", "disabled-icon", "enabled"])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(_component_FileTabs, {
        key: 1,
        class: "file-tab",
        "teleport-tabs-header-to": ".top-part",
        tabName: _ctx.localFiles,
        onOnChangeTab: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isLoadingPdfPage = true))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFiles, (file) => {
            return (_openBlock(), _createBlock(_component_FileTab, {
              key: file.id,
              name: file.name
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_Teleport, { to: ".top-part" }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", {
                        draggable: "true",
                        onDragstart: 
                ($event) => _ctx.dragStamp($event, file, _ctx.companyInfo?.imprintType)
              ,
                        class: "draggable-button button-in-row"
                      }, [
                        _createElementVNode("img", {
                          draggable: "false",
                          src: require('icons/drag-grey.svg')
                        }, null, 8, _hoisted_13),
                        _createVNode(_component_TextButton, {
                          draggable: "false",
                          onOnClick: ($event: any) => (
                  _ctx.addStamp(file, file.currentPage!, {
                    initX: 0,
                    initY: 0,
                    isMobile: false,
                    isRound: _ctx.companyInfo?.imprintType === 1,
                    stampSize: _ctx.companyInfo?.imprintSize || 21,
                  })
                ),
                          text: _ctx.$t('contractVars.stamp'),
                          icon: 
                  _ctx.companyInfo?.imprintType === 1
                    ? require('icons/add-stamp.svg')
                    : require('icons/add-stamp-square-orange.svg')
                ,
                          "disabled-icon": 
                  _ctx.companyInfo?.imprintType === 1
                    ? require('icons/add-stamp-grey.svg')
                    : require('icons/add-stamp-square-grey.svg')
                
                        }, null, 8, ["onOnClick", "text", "icon", "disabled-icon"])
                      ], 40, _hoisted_12),
                      _createElementVNode("div", {
                        draggable: "true",
                        onDragstart: ($event) => _ctx.dragText($event, file),
                        class: "draggable-button button-in-row"
                      }, [
                        _createElementVNode("img", {
                          draggable: "false",
                          src: require('icons/drag-grey.svg')
                        }, null, 8, _hoisted_15),
                        _createVNode(_component_TextButton, {
                          draggable: "false",
                          onOnClick: ($event: any) => (_ctx.addText(file, false, file.currentPage!)),
                          text: _ctx.$t('contractVars.textField'),
                          icon: require('icons/add-text.svg')
                        }, null, 8, ["onOnClick", "text", "icon"])
                      ], 40, _hoisted_14),
                      _createElementVNode("div", {
                        draggable: "true",
                        onDragstart: ($event) => _ctx.dragCheckbox($event, file),
                        class: "draggable-button button-in-row"
                      }, [
                        _createElementVNode("img", {
                          draggable: "false",
                          src: require('icons/drag-grey.svg')
                        }, null, 8, _hoisted_17),
                        _createVNode(_component_TextButton, {
                          draggable: "false",
                          onOnClick: ($event: any) => (_ctx.addText(file, true, file.currentPage)),
                          text: _ctx.$t('contractVars.checkBox'),
                          icon: require('icons/add-checkbox.svg')
                        }, null, 8, ["onOnClick", "text", "icon"])
                      ], 40, _hoisted_16)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("input", {
                        type: "number",
                        class: "jump-page",
                        value: file.currentPage,
                        onInput: ($event) => _ctx.setPage(file, $event)
                      }, null, 40, _hoisted_19),
                      _createElementVNode("p", null, "/" + _toDisplayString(file.pageNums), 1),
                      _createVNode(_component_TextButton, {
                        class: "prev-page",
                        icon: require('icons/bold-prev-arrow.svg'),
                        "disabled-icon": require('icons/prev-arrow-grey.svg'),
                        enabled: file.currentPage >= 2,
                        onOnClick: ($event: any) => (_ctx.prevPage(file))
                      }, null, 8, ["icon", "disabled-icon", "enabled", "onOnClick"]),
                      _createVNode(_component_TextButton, {
                        class: "next-page",
                        "right-icon": require('icons/next-arrow.svg'),
                        "disabled-icon": require('icons/next-arrow-grey.svg'),
                        enabled: file.currentPage != file.pageNums,
                        onOnClick: ($event: any) => (_ctx.nextPage(file))
                      }, null, 8, ["right-icon", "disabled-icon", "enabled", "onOnClick"])
                    ])
                  ])
                ])),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_PdfPreviewVars, {
                      "file-path": file.path,
                      "file-name": file.name,
                      "file-type": file.type,
                      page: file.currentPage,
                      onOnReady: ($event: any) => (_ctx.onReady(file, $event)),
                      onPageScaled: ($event: any) => (_ctx.onPageScaled(file, $event)),
                      onPageSized: ($event: any) => (_ctx.onPageSized(file, $event)),
                      onPageLoaded: 
              (page) => {
                _ctx.isLoadingPdfPage = false;
                _ctx.detectScrollbar();
                _ctx.onPageLoaded(file, page);
              }
            ,
                      class: "workspace",
                      onDragover: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"])),
                      onDrop: _withModifiers(_ctx.handleDrag, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.isLoadingPdfPage)
                          ? (_openBlock(), _createBlock(_component_DraggableContainer, { key: 0 }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(file.inputs.filter(
                  (input) => input.page === file.currentPage
                ), (field, index) => {
                                  return (_openBlock(), _createBlock(_component_TextInputDraggableResizable, {
                                    key: field.id,
                                    id: field.id,
                                    index: index,
                                    "is-optional-text": field.isOptionalText,
                                    "is-stamp": field.isStamp,
                                    "init-h": field.h,
                                    "init-w": field.w,
                                    "init-x": field.x,
                                    "init-y": field.y,
                                    "init-padding": field.padding,
                                    "min-height": field.minHeight,
                                    "init-font-size": field.fontSize,
                                    "default-signer": 
                  _ctx.localSigners.find(
                    (signer) => signer.order === field.contractSignerOrder
                  )?.id || ''
                ,
                                    signers: _ctx.localSigners,
                                    "is-checked": field.isChecked,
                                    type: field.type,
                                    text: field.text,
                                    "stamp-type": field.isRoundStamp,
                                    suggestions: _ctx.suggestions,
                                    onIsCheckedByDefault: 
                  (payload) => _ctx.updateCheckedByDefault(file, field.id, payload)
                ,
                                    onIsRequired: 
                  (payload) => _ctx.updateIsRequired(file, field.id, payload)
                ,
                                    onUpdateCheckBoxText: 
                  (payload) => _ctx.updateCheckBoxText(file, field.id, payload)
                ,
                                    onUpdateFontSize: 
                  (payload) => _ctx.updateFontSize(file, field.id, payload)
                ,
                                    onOnRemoveItem: ($event: any) => (_ctx.onRemoveInput(file, field.id)),
                                    onOnUpdate: (payload) => _ctx.updateField(file, field.id, payload),
                                    onOnUpdateSigner: 
                  (payload) => _ctx.updateSigner(file, field.id, payload)
                
                                  }, null, 8, ["id", "index", "is-optional-text", "is-stamp", "init-h", "init-w", "init-x", "init-y", "init-padding", "min-height", "init-font-size", "default-signer", "signers", "is-checked", "type", "text", "stamp-type", "suggestions", "onIsCheckedByDefault", "onIsRequired", "onUpdateCheckBoxText", "onUpdateFontSize", "onOnRemoveItem", "onOnUpdate", "onOnUpdateSigner"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["file-path", "file-name", "file-type", "page", "onOnReady", "onPageScaled", "onPageSized", "onPageLoaded", "onDrop"])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["tabName"]))
}