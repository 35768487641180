import { renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu-option-popup",
  ref: "root"
}
const _hoisted_2 = { class: "indicator" }
const _hoisted_3 = { class: "options-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "target", { showMenuOption: _ctx.showMenuOption }),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, _ctx.isShowMenu]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "options")
    ], 512), [
      [_vShow, _ctx.isShowMenu]
    ])
  ], 512))
}