
import { ComputedRef, defineComponent, inject, ref } from "vue";

export default defineComponent({
  name: "MenuOptionPopup",
  props: {
    boundClass: { type: String, default: "" },
    isHiddenFromClick: { type: Boolean, default: true },
  },
  setup(props: any) {
    const root = ref(null);
    const isMobile = inject("isMobile") as ComputedRef;
    const isShowMenu = ref(false);

    const showMenuOption = () => {
      isShowMenu.value = !isShowMenu.value;
      const targetBound = (root.value as any).getBoundingClientRect();
      const optionContainer = (root.value as any).querySelector(
        ".options-container"
      ) as HTMLElement;
      const indicator = (root.value as any).querySelector(".indicator");

      if (props.boundClass) {
        const boundContainer = (
          document.querySelector("." + props.boundClass) as HTMLElement
        ).getBoundingClientRect();
        indicator.style.top = targetBound.height + "px";
        indicator.style.left = targetBound.width / 2 - 4 + "px";
        optionContainer.style.left =
          -targetBound.left + boundContainer.left + 10 + "px";
        optionContainer.style.right =
          targetBound.right - boundContainer.right + 10 + "px";
        optionContainer.style.top = targetBound.height + 4 + "px";
      } else if (isMobile.value) {
        indicator.style.top = targetBound.height + "px";
        indicator.style.left = targetBound.width / 2 - 4 + "px";
        optionContainer.style.left = -targetBound.left + 10 + "px";
        optionContainer.style.right =
          targetBound.right - window.screen.width + 10 + "px";
        optionContainer.style.top = targetBound.height + 4 + "px";
      } else {
        indicator.style.top = targetBound.height + "px";
        indicator.style.left = targetBound.width / 2 - 4 + "px";
        optionContainer.style.removeProperty("left");
        optionContainer.style.right = "-10px";
        optionContainer.style.top = targetBound.height + 4 + "px";
      }
    };

    return {
      root,
      isShowMenu,
      showMenuOption,
    };
  },

  mounted() {
    window.addEventListener("click", this.dismiss);
  },

  unmounted() {
    window.removeEventListener("click", this.dismiss);
  },

  methods: {
    dismiss(event: Event) {
      const isClickOption = (this.$el as HTMLLIElement)
        .querySelector(".options-container")
        ?.contains(event.target as Element);
      if (
        this.isShowMenu &&
        (!(this.$el as HTMLElement).contains(event.target as Element) ||
          (isClickOption && this.isHiddenFromClick))
      ) {
        this.isShowMenu = false;
      }
    },
  },
});
