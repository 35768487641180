
import Dropdown from "@/components/atomics/Dropdown.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import {
  defineComponent,
  ref,
  inject,
  PropType,
  computed,
  watch,
  onMounted,
  nextTick,
  onBeforeMount,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import { DraggableContainer } from "vue3-draggable-resizable";
import TextInputDraggableResizable from "@/components/atomics/vars/TextInputDraggableResizable.vue";
import PdfPreviewVars from "@/components/parts/PdfPreviewVars.vue";
import useInputVars from "@/lib/compositional-logic/useInputVars";
import { FileInfo } from "@/models/contracts/FileInfo";
import Signer from "@/models/Signer";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "TemplateVars",
  components: {
    MenuOptionPopup,
    Dropdown,
    TextButton,
    FileTab,
    FileTabs,
    DraggableContainer,
    TextInputDraggableResizable,
    PdfPreviewVars,
  },
  emits: ["update:files", "update:signers"],
  props: {
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<Signer>>,
      required: true,
    },
    detectScrollbar: {
      type: Function,
    },
    companyInfo: {
      type: Object,
      default: {} as any,
    },
    suggestions: {
      type: Array<string>,
      default: [],
    },
  },
  setup(props, { emit }) {
    const isMobile = inject("isMobile");
    const selectedFile = ref(props.files[0] || {});
    const isLoadingPdfPage = ref(true);
    const localFiles = computed({
      get(): FileInfo[] {
        return props.files;
      },

      set(value: FileInfo[]) {
        emit("update:files", value);
      },
    });

    watch(
      () => localFiles.value,
      async (val) => {
        if (val.length > 0) {
          selectedFile.value = val[0];
        }
      },
      { deep: true }
    );

    const localSigners = computed({
      get(): Signer[] {
        return props.signers?.map((signer, index) => ({
          ...signer,
          order: index + 1,
          id: signer.id || signer?.placeholder || "",
          name: signer.placeholder
            ? signer.placeholder
            : signer.lastName + " " + signer.firstName,
        }));
      },

      set(value: Signer[]) {
        emit("update:signers", value);
      },
    });

    const { onPageSized, ...inputVars } = useInputVars();

    const onSelectedFile = (fileId: string) => {
      const file = localFiles.value.find((item) => item.id === fileId);
      isLoadingPdfPage.value = true;
      if (file) selectedFile.value = file;
    };
    const onReady = (file: FileInfo, pageNums: number) => {
      file.pageNums = pageNums;
    };
    const nextPage = (file: FileInfo) => {
      isLoadingPdfPage.value = true;
      if (!file.currentPage || !file.pageNums) return;
      if (file.currentPage < file.pageNums) {
        file.currentPage += 1;
      }
    };
    const prevPage = (file: FileInfo) => {
      isLoadingPdfPage.value = true;
      if (!file.currentPage) return;
      if (file.currentPage > 1) {
        file.currentPage -= 1;
      }
    };

    const setPage = (file: FileInfo, e: any) => {
      isLoadingPdfPage.value = true;
      if (!file.currentPage && !file.pageNums) {
        file.currentPage = 1;
        return;
      }
      const page = parseInt(e.target.value, 10);
      if (page <= 0) {
        file.currentPage = 1;
        e.target.value = 1;
        return;
      } else if (page >= (file.pageNums || 0)) {
        file.currentPage = file.pageNums;
        e.target.value = file.pageNums;
        return;
      } else if (page) file.currentPage = page;
    };

    const handleDrag = (e: any) => {
      const fileId = e.dataTransfer.getData("fileId");
      const file = localFiles.value.find((item: any) => item.id === fileId);
      inputVars.addVarFromDrag(
        e,
        file,
        props.companyInfo?.imprintType === 1 ? "round" : "square",
        props.companyInfo?.imprintSize || 21
      );
    };

    const onPageScaled = (file: any, scale: number) => {
      file.scale = scale;
      file.inputs.forEach((input: any) => {
        input.w = input.originW * scale;
        input.h = input.originH * scale;
        input.x = input.originX * scale;
        input.y = input.originY * scale;
        input.scale = scale;
      });
    };

    const onPageLoaded = (file: any, page: number) => {
      file.inputs?.forEach((input: any) => {
        const scale = !input.scale || input.scale == 0.0 ? 1.0 : input.scale;

        input.originW = input.w / scale;
        input.originH = input.h / scale;
        input.originX = input.x / scale;
        input.originY = input.y / scale;
        console.log(input);
      });
    };

    return {
      selectedFile,
      ...inputVars,
      isMobile,
      localFiles,
      localSigners,
      onPageScaled,
      onPageLoaded,
      onPageSized,
      onSelectedFile,
      onReady,
      nextPage,
      prevPage,
      setPage,
      handleDrag,
      isLoadingPdfPage,
    };
  },
});
